<!--顶部导航栏-->
<template>
  <div class="box">
    <div class="box2">
      <div class="icon">
        <img src="../assets/logo_black.png" />
      </div>
      <div class="middle">
        <div v-for="(item, index) in navList" :key="index" class="list_style" @click="choice(item, index)"
          @mouseenter="enter(index)" @mouseleave="leave()">
          <div :class="[option == index ? 'hover' : '','title']">{{ item.typename }}</div>
          <div :class="[option == index ? 'line' : '','two']"></div>
          <div v-if="item.childs && item.childs.length > 0 && index == 4" v-show="seen && index == current"
            class="flex">
            <div class="menu2" v-for="(sum, index) in navList[index].childs" :key="index">
              <div class="title" @click.stop="choice(sum, 1)">
                {{ sum.typename }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="userInfo" class="loginBtn2">
        <div class="user_name">{{ userInfo.userid }}</div>
        <div class="btn3" @click="signOut">退出</div>
      </div>
      <div class="loginBtn" @click="loginBtn" v-else>
        <div class="loginb">登录</div>
      </div>
    </div>
    <div class="mobile">
      <div class="icon">
        <img src="../assets/logo_black.png" />
      </div>
      <div class="f_btn">
        <div v-if="userInfo" class="loginBtn2">
          <div class="user_name">{{ userInfo.userid }}</div>
          <div class="btn3" @click="signOut">退出</div>
        </div>
        <div class="loginBtn" @click="loginBtn" v-else>
          <div class="loginb">登录</div>
        </div>
        <div style="cursor:pointer">
          <div @click="menu=!menu">--</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { menuList } from "../api/api";
export default {
  data() {
    return {
      navList: [
        {
          title: "网址首页",
          src: "/index",
        },
        {
          title: "中心介绍",
          src: "/centerIntroduced",
        },
        {
          title: "新闻资讯",
          src: "/news",
        },
        {
          title: "技术成果",
          src: "/achievements",
        },
        {
          title: "交易平台",
          src: "/tradingPlatform",
        },
        {
          title: "联系我们",
          src: "/contactUs",
        },
        {
          title: "资料上传",
          src: "/supplierCenter",
        },
        {
          title: "供应商注册",
          src: "/supplier",
        },
      ],
      option: 0,
      userInfo: "",
      Token: "",
      seen: false,
      menu: false,//控制导航栏显示
      current: 0,
    };
  },
  beforeMount() {
    switch (this.$route.path) {
      case "/listDetails":
      case "/index":
        this.option = 0;
        break;
      case "/centerIntroduced":
        this.option = 1;
        break;
      case "/news":
        this.option = 2;
        break;
      case "/generalProfile":
      case "/achievements":
        this.option = 3;
        break;
      case "/tradingPlatform":
      case "/orderEcho":
      case "/supplierCenter":
      case "/dataUpload":
      case "/supplier":
        this.option = 4;
        break;
      case "/contactUs":
      case "/contactUsNew":
        this.option = 6;
        break;
      case "/recruitment":
        this.option = 5;
        break;
    }
    this.Token = localStorage.getItem("token");
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getMenu();
  },
  methods: {
    choice(item, index) {
      let newpid = item.pid;
      this.option = index;
      // localStorage.setItem("option", index);
      if(item.href==this.$route.path) return
      this.$router.push({
        path: item.href,
        query: {
          pid: newpid,
        },
      });

      if (this.option == 7 || this.option == 6) {
        if (!this.Token) {
          this.$confirm("请先登录", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/login");
            })
            .catch(() => { });
          return;
        }
      }
    },
    loginBtn() {
      this.$router.push("/login");
    },
    signOut() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          this.$router.push("/login");
        })
        .catch(() => {
        });
    },
    // 获取菜单
    async getMenu() {
      let res = await menuList();

      if (res.ResultCode == 0) {
        res.Data.splice(5, 2);
        let arr = [
          {
            typename: "人才招聘",
            href: "/recruitment",
          },
          {
            typename: "联系我们",
            href: "/contactUs",
          },
        ];
        let kids = [
          {
            typename: "资料上传",
            href: "/supplierCenter",
          },
          {
            typename: "供应商注册",
            href: "/supplier",
          },
        ];
        let list = res.Data.concat(arr);
        this.navList = list.map((item, index) => {
          if (index == 4) {
            item["childs"] = kids;
          }
          return item;
        });
      }
    },
    enter(index) {
      this.seen = true;
      this.current = index;
    },
    leave() {
      this.seen = false;
      this.current = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  position: absolute;
  left: 0.8125rem;
  top: 50px;
  height: 4.125rem;
  width: 6.25rem;
  min-width: 80px;
  min-height: 62px;
  margin: auto;
  margin-top: 1.25rem;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.625rem;

  .menu2 {
    height: 31px;
    line-height: 31px;
    color: #fff;
    width: 6.25rem;
    min-width: 80px;
    cursor: pointer;

    .title {}
  }

  .list_style .title .flex {
    display: none;
  }

  .list_style .title:hover .flex {
    display: block;
  }
}

.box {
  width: 100vw;
  min-width: 0;
  background-color: #fff;
  color: #333;
  border-bottom: 1px solid #f4f4f4;
  height: 74px;

  .box2 {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    margin: 0 auto;
    width: 93.75rem;
    font-size: 0.875rem;

  }

  .icon {
    width: 21.25rem;
    height: 3.125rem;

    img {
      width: 100%;
      max-width: 340px;
      max-height: 50px;
      height: 100%;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  .middle {
    height: 74px;
    line-height: 74px;
    display: flex;
    // align-items: center;
    width: 50rem;
    padding-left: 4.4375rem;

    .list_style {
      color: #333;
      font-size: 1rem;
      text-align: center;
      flex: 1;
      position: relative;
      user-select: none;
      cursor: pointer;
      font-family: '微软雅黑';
      // padding: 1.25rem 0;
    }

    .hover {

      color: #356CEB;
      position: relative;
    }

    .line {
      width: 30px;
      height: 3px;
      background: #356CEB;
      margin: 0 auto;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: 0%;

    }
  }

  .loginBtn {
    .loginb {
      color: #333;
      text-align: center;
      font-size: 0.875rem;
      padding: 0.325rem 1.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 120px;
      border: 1px solid #333;
      border-radius: 1.25rem;
    }
  }

  .loginBtn2 {
    color: #333;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    .user_name {
    }

    .btn3 {
      width: 5rem;
      height: 1.875rem;
      line-height: 1.875rem;
      border: 1px solid #333;
      border-radius: 1.25rem;
      margin-left: 1.25rem;
    }
  }
}

.mobile {

  display: none;

  .middle {
    position: absolute;
    width: 100px;
    top: 50px;
    right: 0;
    z-index: 999;
    flex-direction: column;
    background-color: #fff;
  }
}

.f_btn {
  display: flex;

  &>div {
    padding: 0 10px;
  }
}
</style>


<style lang="scss" scoped>
@media only screen and (max-width: 1200px) {
  .box2 {
    width: 100%;
    height: 74px;
    display: block;
  }

  .mobile {
    height: 74px;
    display: none;
  }
}

@media only screen and (max-width: 980px) {
  .box2 {
    width: 100%;
    height: 74px;
    display: block;
  }

  .mobile {
    display: none;
  }

}

@media only screen and (max-width: 640px) {
  .box {

    .box2 {
      width: 100%;
      display: none;
    }

    .icon {
      width: 21.25rem;
      height: 3.125rem;
      min-width: 270px;
      min-height: 45px;

      img {
        width: 90%;
        height: 90%;
        max-width: 340px;
        max-height: 50px;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }

  .mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;

    .middle {
      padding-left: 0;
      .list_style{
        // height: 50px;
      background-color: #fff;
      }
      .line {
        display: none;
      }
    }
  }

  .flex {
    position: relative;
    left: 5px;
    top: 0;
    height: 50px;
    width: 6.25rem;
    min-width: 80px;
    min-height: 50px;
    // text-align: right;
    border-radius: 0.625rem;
    background-color: #f4f4f4;

    .menu2 {
      height: 25px;
      line-height: 25px;
      color: #fff;
      width: 6.25rem;
      min-width: 80px;
      cursor: pointer;

      .title {
        color: gray;
      }
    }

  }



}
</style>