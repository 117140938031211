<!--注册页面-->
<template>
  <div class="box">
    <div class="top"><top-nav></top-nav></div>
    <h1>注册账号</h1>
    <div class="line"></div>
    <div class="content">
      <div class="content2">
        <div class="input_box">
          <div class="title">手机号码：</div>
          <div>
            <input type="text" placeholder="请输入手机号码" v-model="phone" />
          </div>
        </div>
        <div class="code">
          <div class="input_box2">
            <div class="title">验证码：</div>
            <div>
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="code"
              />
            </div>
          </div>
          <div class="codeBtn" v-show="!verificationShow" @click="codeBtn">
            获取验证码
          </div>
          <div class="codeBtn" v-show="verificationShow">
            {{ verificationCode }}s
          </div>
        </div>
        <div class="input_box">
          <div class="title">密码：</div>
          <div>
            <input type="password" placeholder="请输入密码" v-model="password" />
          </div>
        </div>
        <div class="input_box">
          <div class="title">确认密码：</div>
          <div>
            <input
              type="password"
              placeholder="请输入确认密码"
              v-model="password2"
            />
          </div>
        </div>
        <button class="btn" @click="register">立即注册</button>
        <div class="bom_title" @click="toLogin">已有账号立即登录 ></div>
      </div>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav"
import {getCode} from "../api/api"
import {getRegister} from "../api/api"

export default {
  components: {
    topNav,
  },
  data() {
    return {
      phone: "", //手机号码
      code:'',//验证码
      password: "", //密码
      verificationCode: 60,
      verificationShow: false,
      password2: "", //确认密码
    };
  },mounted(){
  },
  methods: {
    //点击验证码按钮
    codeBtn() {
      if(!this.phone){
        this.$message({
          message: '手机号不能为空',
          type: 'warning'
        });
        return
      }
      this.verificationShow = true;
      let timer = setInterval(() => {
        if (this.verificationCode > 1) {
          this.verificationCode--;
        } else {
          this.verificationShow = false;
          clearInterval(timer);
        }
      }, 1000);
      this.getCode()
    },
    //获取验证码
    async getCode(){
      let that = this
      let res = await getCode({
        phone:that.phone
      })
      if(res.ResultCode == 0){
        console.log(res,'res')
      }
    },
    //立即注册
    register(){
      if(!this.password){
        this.$message({
          message: '密码不能为空',
          type: 'error'
        });
        return
      }
      if(!this.password2){
        this.$message({
          message: '确认密码不能为空',
          type: 'error'
        });
        return
      }
      if(this.password != this.password2){
        this.$message({
          message: '两次密码不一致',
          type: 'error'
        });
        return
      }
      this.register2()
    },
    //注册
    async register2(){
      let res = await getRegister({
        password:this.password,
        username:this.phone,
        vercode:this.code
      })
      if(res.ResultCode == 0){
        this.$message({
          message: '注册成功',
          type: 'seccess'
        });
        setTimeout(()=>{
          this.$router.push('/index')
        },1500)
      }else{
        this.$message({
          message: res.ResultInfo,
          type: 'error'
        });
      }
    },
    //跳转登录页面
    toLogin(){
      this.$router.push('/login')
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
.top{
  width: 100vw;
  height: 200px;
  background-image: url("../assets/navBg.png");
  background-size: 100vw 200px;
  background-repeat: no-repeat;
}
  h1 {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-top: 108px;
  }
  .line {
    width: 70px;
    height: 5px;
    background: #2477e4;
    margin: 0 auto;
    margin-top: 23px;
  }
  .content {
    width: 1200px;
    height: 590px;
    background: #f3f3f3;
    padding-top: 1px;
    margin: 0 auto;
    margin-top: 52px;
    .content2 {
      width: 1142px;
      height: 536px;
      background: #ffffff;
      margin: 27px auto;
      padding-top: 56px;
      .input_box {
        display: flex;
        align-items: center;
        width: 483px;
        height: 44px;
        background: #f4f4f4;
        border: 1px solid #eaeaea;
        margin: 0 auto;
        margin-bottom: 23px;
        .title {
          width: 86px;
          text-align: right;
          font-size: 14px;
        }
        input {
          background: #f4f4f4;
          font-size: 14px;
        }
      }
      .code {
        width: 483px;
        margin: 0 auto;
        margin-bottom: 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .input_box2 {
          display: flex;
          align-items: center;
          width: 365px;
          height: 44px;
          background: #f4f4f4;
          border: 1px solid #eaeaea;
          .title {
            width: 86px;
            text-align: right;
            font-size: 14px;
          }
          input {
            background: #f4f4f4;
            font-size: 14px;
          }
        }
        .codeBtn {
          width: 112px;
          height: 44px;
          background: #2477e4;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 44px;
        }
      }
      .btn {
        width: 483px;
        height: 48px;
        background: #2477e4;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        border: none;
        // margin: 0 auto;
        margin-top: 20px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
      .bom_title {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        text-align: center;
        margin-top: 35px;
      }
    }
  }
}
</style>
